let viewpages = [
  {

    path: '/',
    name: 'home',
    component: () => import( '../views/HomeView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/home/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/home/ContactView.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import( '../views/home/ServiceView.vue')
  },
  {
    path: '/service_VF',
    name: 'service_VF',
    component: () => import( '../views/home/Service_VFView.vue')
  },
  {
    path: '/service_VF_ex',
    name: 'service_VF_ex',
    component: () => import( '../views/home/Service_VF_exView.vue')
  },
  {
    path: '/service_VK',
    name: 'service_VK',
    component: () => import( '../views/home/Service_VKView.vue')
  },
  {
    path: '/service_VK_ex',
    name: 'service_VK_ex',
    component: () => import( '../views/home/Service_VK_exView.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import( '../views/home/ProductView.vue')
  },
  {
    path: '/product_VF',
    name: 'product_VF',
    component: () => import( '../views/home/Product_VFView.vue')
  },
  {
    path: '/product_VK',
    name: 'product_VK',
    component: () => import( '../views/home/Product_VKView.vue')
  }

]

export default viewpages