<template>
    <div>
    
    <nav class="navbar navbar-expand-lg  navbar-dark bg-dark">
      <div class="container">

        <span style="background-color: rgb(0, 0, 0);">
        <router-link class="navbar-brand" to="/"><img src="@/assets/images/HD_Icon_m.jpg" width="30" height="30" class="img-fluid " alt="...">
            <span style="color: rgb(255, 255, 255);"> 鴻鼎時代股份有限公司</span>
        </router-link>
                  
        </span>
        <button class="navbar-toggler" type="button" 
        data-bs-toggle="collapse" 
        data-bs-target="#navbarNav" 
        data-toggle="collapse" 
        data-target="#navbarNav" 
        aria-controls="navbarNav" 
        aria-expanded="false" 
        aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
                <router-link class="nav-link" to="/"><span class="fa fa-home"></span> 首頁</router-link>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/about"><span class="fa fa-info"></span> 關於</router-link>
            </li>

            <li class="nav-item dropdown">
              <router-link class="nav-link" to="/service" id="dropdownMenuButton1" 
                data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-cog"></i> 技術
              </router-link>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link class="dropdown-item" to="/service">總覽</router-link>
                  <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" to="/service_VK">VK 技術說明</router-link>
                <router-link class="dropdown-item" to="/service_VK_ex">VK 案例</router-link>
                  <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" to="/service_VF">VF 技術說明</router-link>
                <router-link class="dropdown-item" to="/service_VF_ex">VF 案例</router-link>
              </div>           
            </li>

            <li class="nav-item dropdown">
                <router-link class="nav-link" to="/product" id="dropdownMenuButton" 
                data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-hand-holding"></i> 產品與服務</router-link>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link class="dropdown-item" to="/product">總覽</router-link>
                 <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" to="/product_VK">VK 設備資料</router-link>
                <router-link class="dropdown-item" to="/product_VF">VF 設備資料</router-link>
              </div>
            </li>

          <li class="nav-item">              
                    <a :href="'#Z'" class="nav-link" title="操作手冊" @click="openFile"><i class="fas fa-book-open"></i> 簡報資料</a>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/contact">
                <i class="fa fa-address-book" aria-hidden="true"></i> 聯絡我們
                </router-link>
            </li>

          </ul>
        </div>

      </div>
    </nav> 

    <!-- <nav class="navbar navbar-expand-lg  navbar-dark bg-dark">
          <div class="container">

            <span style="background-color: white;">
            <router-link class="navbar-brand" to="/"><img src="@/assets/images/HD_Icon_m.jpg" width="30" height="30" class="img-fluid " alt="...">
                <span style="color: black;">鴻鼎時代股份有限公司</span>
            </router-link>
                      
            </span>
            <button class="navbar-toggler" type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarNav" 
            data-toggle="collapse" 
            data-target="#navbarNav" 
            aria-controls="navbarNav" 
            aria-expanded="false" 
            aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav ms-auto" >
                <template v-for="(item, index) in menudatas" :key="index">
                <li class="nav-item" v-if="!item.dropdown">
                    <router-link class="nav-link" :to="item.link"><span :class="item.icon"></span> {{ item.displayname }}</router-link>
                </li>

                <li class="nav-item dropdown" v-if="item.dropdown" >
                  <router-link class="nav-link" :to="item.link" id="dropdownMenuButton{{ item.id }}" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                        <span :class="item.icon"></span> {{ item.displayname }}
                  </router-link>
                  <Sidebar_Children1Component :list="item.childrendata" />

                </li>
                </template>         
              </ul>
            </div>
          </div>
    </nav>  -->

    
    </div>
</template>


<script>
//import Sidebar_Children1Component from '@/components/Sidebar_Children1Component.vue'

import {
  defineComponent,
  ref,
} from "vue";

export default defineComponent({
  components:{
   //Sidebar_Children1Component
  },
  setup() {
    let menudatas = ref([
      { id:1, name:'home',  link:'/', dropdown:false, childrendata:[], icon:'fa fa-home', displayname:'首頁'},
      { id:2, name:'about', link:'/about', dropdown:false, childrendata:[], icon:'fa fa-info', displayname:'關於'},
      { id:3, name:'serviceroot', link:'', dropdown:true, icon:'fa fa-cog', displayname:'技術',
        childrendata:[
         {id:31, name:'service', link:'/service', displayname:'總覽' },
         {id:32, name:'', link:'', displayname:'' },
         {id:33, name:'service_VK',  link:'/service_VK', displayname:'VK 技術說明' },
         {id:34, name:'service_VK_ex',  link:'/service_VK_ex', displayname:'VK 案例' },
         {id:35, name:'',  link:'', displayname:'' },
         {id:36, name:'service_VF',  link:'/service_VF', displayname:'VF 技術說明' },
         {id:37, name:'service_VF_ex',  link:'/service_VF_ex', displayname:'VF 案例' },
         ]
       },
       { id:4, name:'productroot', link:'', dropdown:true, icon:'fa fa-hand-holding', displayname:'產品與服務',
        childrendata:[
         {id:41, name:'product', link:'/product', displayname:'總覽' },
         {id:42, name:'', link:'', displayname:'' },
         {id:43, name:'product_VK',  link:'/product_VK', displayname:'VK 設備資料' },
         {id:44, name: 'product_VF',  link:'/product_VF', displayname:'VF 設備資料' },
         ]
       },

      { id:5, name:'contact',  link:'/contact', dropdown:false, childrendata:[], icon:'fa fa-address-book', displayname:'聯絡我們'},
    ]);
    // const getDatas = (datas)=>{
    //     datas.forEach((x) => {
    //       console.log(x.isline)
    //     });
    //     return datas;
    // };
//const openFile = () => {
//    window.open("鴻鼎時代是節能的專家.pdf", "_blank");
//};
    return{
      menudatas,
      //getDatas
    }

  },
  methods: {
    openFile()  {
    window.open("鴻鼎時代是節能的專家.pdf", "_blank");
  }
}
})



</script>
