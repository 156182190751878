import { createStore } from 'vuex'

export default createStore({

  state: {
    windowWidth: 0,
  },
 
  getters: {
    windowWidth: state => state.windowWidth,
  },
 
  mutations: {
    setWindowWidth(state, value) {
      state.windowWidth = value;
    }
  }
})
