import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueYtframe from "vue3-ytframe"


//import Embed from 'v-video-embed'
createApp(App).use(store).use(router).use(VueAxios, axios)
//.use(Embed)
.use(VueYtframe)
.mount('#app')
