import { createRouter, createWebHistory } from 'vue-router'
import viewpages from './viewPages' 

const routes = [  
  {
  
    children:[
    ...viewpages
    ]
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
